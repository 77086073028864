import './AssetCard.css'

import React, { Component } from 'react';
import { Card, CardActionArea, CardContent, Grid, Tooltip, Typography } from '@material-ui/core';
import MentionsBar from '../MentionsBar/MentionsBar';
import { withRouter } from 'react-router-dom';
import TrendDisplay from '../TrendDisplay/TrendDisplay';

class AssetCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: props.index,
            symbol: props.symbol,
            sentiment: props.sentiment,
            dailyChange: props.dailyChange,
            positive: props.positive,
            neutral: props.neutral,
            negative: props.negative,
            sentimentScore: props.sentimentScore,
            prevSentiment: props.prevSentiment,
            lastUpdated: props.lastUpdated
        };
    }

    handleNavigate = () => {
        this.props.history.push(`/stocks/${this.state.symbol}`)
    }

    render() {
        const data = [{ neg: this.state.negative, ment: this.state.neutral, pos: this.state.positive }];
        const updated = new Date(this.state.lastUpdated*1000);
        const now = new Date();
        const since = now.getTime() - updated.getTime(); //ms since update
        return (
            <Card className="AssetCard">
                <CardActionArea onClick={this.handleNavigate}>
                    <CardContent className="AssetCardContent">
                        <div className="TopInfoPane">
                            <div className="SymbolBox">
                                <Typography gutterBottom variant="BUTTON TEXT" component="h2">
                                    {this.state.symbol}
                                </Typography>
                            </div>
                            <Typography variant="h5" color="textSecondary" component="p">
                                {this.state.sentimentScore}
                            </Typography>
                            <TrendDisplay value={this.state.dailyChange}
                                priorValue={this.state.prevSentiment} />
                        </div>
                        <Tooltip title={"Neutral: " + this.state.neutral + " Negative: " + this.state.negative + " Positive: " + this.state.positive} arrow placement="top">
                            <div className="BottomInfoPane">
                                <Grid container className="SentimentBar" direction="row" spacing={1}>
                                    <Grid item xs={7} md={9} >
                                        <MentionsBar data={data} posKey="pos" mentionKey="ment" negKey="neg" />
                                    </Grid>
                                    <Grid item xs={5} md={3}>
                                        <Typography variant="caption text" color="textSecondary" align="right">
                                            {(since/60000).toFixed()}m ago
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Tooltip>
                    </CardContent>
                </CardActionArea>
            </Card >
        );
    }
}

export default withRouter(AssetCard);
