import TextField from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import './SymbolSearchBar.css'

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

class SymbolSearchBar extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleTextUpdate = (event) => {
        this.setState({
            textFieldValue: event.target.value
        });
    };

    handleNavigate = () => {
        if (!!this.state.textFieldValue) {
            this.props.history.push(`/stocks/${this.state.textFieldValue}`)
            this.setState({
                textFieldValue: ''
            });
        }
    }

    textKeyDown = (event) => {
        if (event.keyCode === 13) {
            this.handleNavigate();
        }
    }

    render() {
        return (
            <div className="SymbolSearchBar">
                <TextField size="small" placeholder="Search Asset Symbol..." className="SymbolTextField" id="outlined-basic"
                    variant="outlined" value={this.state.textFieldValue} onChange={this.handleTextUpdate} onKeyDown={this.textKeyDown}
                />
                <IconButton size="small" className="SymbolGoButton" aria-label="Go" onClick={this.handleNavigate}>
                    <ArrowForwardIcon />
                </IconButton>
            </div>
        );
    }
}

export default withRouter(SymbolSearchBar);
