import './SortedAssetsList.css'

import React, { Component } from 'react';
import { Container, IconButton, Select, Tab, Tabs, Tooltip } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AssetCard from '../AssetCard/AssetCard';
import apigClientFactory from 'aws-api-gateway-client';
import ReactGA from 'react-ga';

class SortedAssetsList extends Component {

    constructor(props) {
        super(props);
        this.apigClient = apigClientFactory.newClient({ invokeUrl: 'https://qio45dvava.execute-api.us-west-2.amazonaws.com/prod' });

        this.state = {
            sort: "daily_mentions",
            ascending: false,
            tab: 0,
            stocks: [],
        };

        this.getData();
    }



    getData = async () => {
        try {
            const response = await this.apigClient.invokeApi({}, '/stocks', 'GET', { headers: {}, queryParams: { sortBy: this.state.sort, sortDirection: this.state.ascending ? 'ASC' : 'DESC' } });
            this.setState({ stocks: response.data });
        } catch (e) {
            alert("Derp... Sorry we failed to update results... ", e);
        }
    }

    handleSortChange = async (event) => {
        ReactGA.event({sort: event.target.value});
        this.setState({ stocks: [], sort: event.target.value });
        try {
            const response = await this.apigClient.invokeApi({}, '/stocks', 'GET', { headers: {}, queryParams: { sortBy: event.target.value, sortDirection: this.state.ascending ? 'ASC' : 'DESC' } });
            this.setState({ stocks: response.data })
        } catch (e) {
            alert("Derp... Sorry, we failed to update results.")
        }
    }

    toggleDirection = async () => {
        this.setState(prevState => ({ stocks: [], ascending: !prevState.ascending }));
        try {
            const response = await this.apigClient.invokeApi({}, '/stocks', 'GET', { headers: {}, queryParams: { sortBy: this.state.sort, sortDirection: !this.state.ascending ? 'ASC' : 'DESC' } });
            this.setState({ stocks: response.data });
        } catch (e) {
            alert("Derp... Sorry, we failed to update results... ", e);
        }
    }

    handleTabSelection = (event, newValue) => {
        this.setState({ tab: newValue });
    };

    render() {
        return (
            <div className="SortedAssetsList">
                <div className="TabAndSort">
                    <Tabs className="AssetTabs"
                        value={this.state.tab}
                        indicatorColor="primary"
                        textcolor="primary"
                        onChange={this.handleTabSelection}
                        aria-label="Asset Selection"
                    >
                        <Tab label="Stocks" />
                        <Tooltip title="Coming soon..." arrow placement="top">
                            <span>
                                <Tab label="Crypto" disabled />
                            </span>
                        </Tooltip>
                    </Tabs>
                    <div className="SortAndDirectionSelection">
                        <Select
                            className="SortSelection"
                            native
                            variant="outlined"
                            value={this.state.sort}
                            onChange={this.handleSortChange}
                            label="Sort"
                            inputProps={{
                                name: 'sort',
                                id: 'outlined-sort-native-simple',
                            }}
                        >
                            <option value={'daily_mentions'}>Total Daily Mentions</option>
                            <option value={'daily_negative'}>Daily Negative</option>
                            <option value={'daily_positive'}>Daily Positive</option>
                            <option value={'daily_sentiment_change'}>Daily Sentiment Change</option>
                            <option value={'stock_sentiment'}>Sentiment Score</option>
                            <option value={'weekly_mentions'}>Total Weekly Mentions</option>
                            <option value={'weekly_negative'}>Weekly Negative</option>
                            <option value={'weekly_positive'}>Weekly Positive</option>
                            <option value={'weekly_sentiment_change'}>Weekly Sentiment Change</option>
                        </Select><IconButton className="SortDirectionButton" aria-label="Asc/Desc" onClick={this.toggleDirection}>
                            {this.state.ascending ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                        </IconButton>


                    </div>
                </div>

                <Container className="AssetCardList">
                    {this.state.stocks.map((asset, index) => (
                        <AssetCard key={asset.ticker} index={index + 1} symbol={asset.ticker} sentiment={asset.stock_sentiment}
                            dailyChange={asset.daily_sentiment_change} positive={asset.daily_positive}
                            prevSentiment={(asset.historical_daily_sentiment && asset.historical_daily_sentiment.length > 0) ? asset.historical_daily_sentiment[asset.historical_daily_sentiment.length-1] : 0}
                            neutral={asset.daily_mentions - asset.daily_positive - asset.daily_negative}
                            negative={asset.daily_negative} sentimentScore={asset.stock_sentiment} lastUpdated={asset.lastUpdated}/>
                    ))}
                </Container>
            </div>
        );
    }
}

export default SortedAssetsList;
