import { Typography } from "@material-ui/core";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import "./BannerLogo.css"

const BannerLogo = () => {

    return (
        <h1 className="BannerLogo">
            <Link to="/stocks">
                <img src={process.env.PUBLIC_URL + '/tickerprime96dpi.png'} height="50px" alt="TickerPrime" />
                <Typography variant="body2" color="textSecondary">
                    Monitor Social Interest and Sentiment Across Reddit and Twitter For Public Assets
                </Typography>
            </Link>
        </h1>
    );
}

export default withRouter(BannerLogo);