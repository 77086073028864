import { Grid, Typography } from "@material-ui/core";
import React from "react";
import "./Footer.css";


export default function Footer() {
    return (
        <footer className="Footer">
            <Grid container className="FooterGrid" direction="row" spacing={1}>
                <Grid item xs={4} >
                    <Typography variant="body2" color="inherit" ml={2} >
                        © 2021 Among Mountains LLC
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body2" color="inherit">
                        Provided strictly for informational purposes, not intended as financial advice.
                    </Typography>
                </Grid>
            </Grid>
        </footer>
    )
}