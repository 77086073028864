import React from "react";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis } from "recharts";

import "./MentionsBar.css";

const MentionsBar = ({ data, posKey, mentionKey, negKey }) => {
  return (
    <ResponsiveContainer className="MentionsBar" width={"100%"} height={32}>
      <BarChart
        data={data}
        width={500}
        height={32}
        layout="vertical"
      >
        <XAxis hide axisLine={false} type="number" domain={[0,10000]}/>
        <YAxis
          hide
          type="category"
          axisLine={false}
          tickLine={false}
        />
        <Bar
          dataKey={negKey}
          fill="red"
          stackId="a"
          minPointSize={2}
          barSize={5}
        />
        <Bar
          dataKey={mentionKey}
          fill="silver"
          stackId="a"
          minPointSize={2}
          barSize={5}
        />
        <Bar
          dataKey={posKey}
          fill="forestgreen"
          stackId="a"
          minPointSize={2}
          barSize={5}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MentionsBar;