import React from 'react';
import { Fragment } from 'react';
import BannerLogo from '../BannerLogo/BannerLogo';
import SortedAssetsList from '../SortedAssetsList/SortedAssetsList';
import ReactGA from 'react-ga';

import SymbolSearchBarWithRouter from '../SymbolSearchBar/SymbolSearchBar'

import './TopStocksView.css'
import { Grid } from '@material-ui/core';
import TweetSampler from '../TweetSampler/TweetSampler';

const TopStocksView = () => {
    ReactGA.set({ page: 'topStocks-default' }); // Update the user's current page
    ReactGA.pageview('topStocks'); // Record a pageview for the given page
    return (
        <Fragment>
            <div className="TopStocksView">
                <BannerLogo />
                <SymbolSearchBarWithRouter className="SymbolSearchBar" id="outlined-basic" variant="outlined" />

                <Grid className="ListAndSample" container direction="row" spacing={1} >
                    <Grid item xs={12} md={8} >
                        <SortedAssetsList className="SortedAssetsList" id="sorted-assets" variant="outlined" xs={8} />
                    </Grid>
                    <Grid className="SampleTweet" item xs={12} md={4} >
                        <TweetSampler />
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    );
}

export default TopStocksView;