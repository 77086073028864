import { Box, Divider, Grid, Tooltip, Typography } from "@material-ui/core";
import { Component } from "react";
import { TwitterTweetEmbed } from "react-twitter-embed";
import apigClientFactory from 'aws-api-gateway-client';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import TrendDisplay from "../TrendDisplay/TrendDisplay";

import "./SingleAssetDetail.css"

class SingleAssetDetail extends Component {

    static monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    constructor(props) {
        super(props);
        this.apigClient = apigClientFactory.newClient({ invokeUrl: 'https://qio45dvava.execute-api.us-west-2.amazonaws.com/prod' });

        this.state = {
            loading: true,
            symbol: props.symbol.toUpperCase(),
            assetData: null,
        };

        this.getData();
    }

    getData = async () => {
        try {
            const response = await this.apigClient.invokeApi({}, '/stocks/' + this.state.symbol, 'GET', { headers: {}, queryParams: {} });
            this.setState({ assetData: response.data.Item, loading: false });
        } catch (e) {
            alert("Derp... Sorry we failed to update results... ", e);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.symbol.toUpperCase() !== prevProps.symbol.toUpperCase()) {
            //props change starting
            this.setState({
                symbol: this.props.symbol.toUpperCase(),
                loading: true,
            });
        }
        if (this.state.symbol !== prevState.symbol) {
            //state change - fetch
            this.getData();
        }
    }

    render() {
        if (this.state.loading) {
            return null;
        }
        let graphData = [];
        if (this.state.assetData) {
            const chartDays = 7;
            for (let i = chartDays - 1; i >= 0; --i) {
                let chartDay = new Date();
                const historicalIndex = this.state.assetData.historical_daily_sentiment.L.length - i;
                chartDay.setDate(chartDay.getDate() - i);
                let chartData = {
                    date: `${SingleAssetDetail.monthNames[chartDay.getMonth()]} ${chartDay.getDate()}`,
                    sentiment: null,
                    mentions: null,
                };
                if (i === 0) {
                    chartData.sentiment = parseInt(this.state.assetData.stock_sentiment.N);
                    chartData.mentions = parseInt(this.state.assetData.daily_mentions.N);
                } else if (historicalIndex >= 0) {
                    if (this.state.assetData.historical_daily_sentiment && this.state.assetData.historical_daily_sentiment.L.length > historicalIndex) {
                        chartData.sentiment = parseInt(this.state.assetData.historical_daily_sentiment.L[historicalIndex].N);
                    }
                    if (this.state.assetData.historical_daily_mentions && this.state.assetData.historical_daily_mentions.L.length > historicalIndex) {
                        chartData.mentions = parseInt(this.state.assetData.historical_daily_mentions.L[historicalIndex].N);
                    }
                }

                graphData.push(chartData);
            }
        } else {
            return (
                <Typography className="AssetNotFound" variant="h3">
                    Sorry we don't support any asset at this time with corresponding symbol: {this.state.symbol}
                </Typography>
            )
        }
        return (
            <Box id="OuterBox" className="SingleAssetDetail"
            >
                <Grid container spacing={1} >
                    <Grid item xs={4} md={4}>
                        <Typography className="DetailSymbol" variant="h4">
                            {this.state.symbol}
                        </Typography>
                    </Grid>
                    {this.state.assetData &&
                        <Grid item xs={8} md={8}>
                            <Typography variant="h5" className="DetailName">
                                {this.state.assetData.assetName ? this.state.assetData.assetName.S : this.state.assetData.aliases.L[0].S}
                            </Typography>
                        </Grid>}
                    <Grid item xs={12} md={4}>
                        <Divider />
                        <Grid container >
                            {this.state.assetData &&
                                <Grid item xs={3} md={12}>
                                    <Typography variant="subtitle1">
                                        Sentiment
                                    </Typography>
                                </Grid>}
                            {this.state.assetData &&
                                <Grid item xs={9} md={12}>
                                    <Tooltip title="Sentiment Score" arrow placement="left">
                                        <Typography variant="h5">
                                            {this.state.assetData.stock_sentiment.N}
                                        </Typography>
                                    </Tooltip>
                                </Grid>}
                            {this.state.assetData &&
                                <Grid item xs={2} md={12}>
                                    <Divider/>
                                    <Typography variant="subtitle1">
                                        Daily
                                    </Typography>
                                </Grid>}
                            {this.state.assetData &&
                                <Grid item xs={10} md={12}>
                                    <TrendDisplay value={this.state.assetData.daily_sentiment_change.N}
                                        priorValue={this.state.assetData.historical_daily_sentiment ?
                                            this.state.assetData.historical_daily_sentiment.L[this.state.assetData.historical_daily_sentiment.L.length - 1].N
                                            : null} />
                                </Grid>}
                            {this.state.assetData &&
                                <Grid item xs={2} md={12}>
                                    <Divider/>
                                    <Typography variant="subtitle1">
                                        Weekly
                                    </Typography>
                                </Grid>}
                            {this.state.assetData &&
                                <Grid item xs={10} md={12}>
                                    <TrendDisplay value={this.state.assetData.weekly_sentiment_change.N}
                                        priorValue={this.state.assetData.historical_weekly_sentiment ?
                                            this.state.assetData.historical_weekly_sentiment.L[this.state.assetData.historical_weekly_sentiment.L.length - 1].N
                                            : null} />
                                </Grid>}
                        </Grid>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={8} className="ChartGridItem">
                        <ResponsiveContainer>
                            <LineChart data={graphData}>
                                <XAxis dataKey="date" />
                                <YAxis />
                                <CartesianGrid stroke="#ccc" />
                                <Legend />
                                <Line type="monotone" dataKey="sentiment" stroke="#8884d8" />
                                <Line type="monotone" dataKey="mentions" stroke="#bbe612" />
                            </LineChart>
                        </ResponsiveContainer>
                    </Grid>
                    <Grid item className="TwitterExample1"  xs={12} md={6}>
                        {!this.state.loading && this.state.assetData && this.state.assetData.positive_example ?
                            <TwitterTweetEmbed
                                // options={{width: 500}}
                                tweetId={this.state.assetData.positive_example.S}
                            /> : null}
                    </Grid>
                    <Grid item className="TwitterExample2" xs={12} md={6}>
                        {!this.state.loading && this.state.assetData && this.state.assetData.negative_example ?
                            <TwitterTweetEmbed
                                // options={{width: 500}}
                                tweetId={this.state.assetData.negative_example.S}
                            /> : null}
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

export default SingleAssetDetail;