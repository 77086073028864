import "./TrendDisplay.css"
import TrendingUp from '@material-ui/icons/TrendingUp';
import TrendingDown from '@material-ui/icons/TrendingDown';
import { Typography } from "@material-ui/core";

const TrendDisplay = (props) => {
    let color = props.value > 0 ? 'lightgreen' : 'red';
    let pctChangeDisplay = "-";
    const pctChange = ( (props.value / Math.abs(props.priorValue)) * 100.);
    if (isFinite(pctChange) && !isNaN(pctChange)) {
        pctChangeDisplay = pctChange.toFixed(2) + "%";
    }
    return (
        <div className="TrendDisplay">
            {props.value && props.value >= 0 && <TrendingUp fontSize="large" />}
            {props.value && props.value < 0 && <TrendingDown fontSize="large" />}
            {
                <Typography className="TrendValue" variant="h6" color="textSecondary" component="p" 
                sx={{ color: {color} }}>
                    {props.value}
                </Typography>
            }
            {props.priorValue &&
                <Typography className="TrendPct" variant="h6" color="textSecondary" component="p"
                sx={{ color: {color} }}>
                    ({pctChangeDisplay})
                </Typography>
            }
        </div>
    );
}

export default TrendDisplay;