import { Component } from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import apigClientFactory from 'aws-api-gateway-client';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { Container } from 'react-bootstrap';


import "./TweetSampler.css"
import React from "react";

class TweetSampler extends Component {


    constructor(props) {
        super(props);
        this.apigClient = apigClientFactory.newClient({ invokeUrl: 'https://qio45dvava.execute-api.us-west-2.amazonaws.com/prod' });

        this.state = {
            loading: true,
            tweetId: null,
        };
    }

    componentDidMount() {
        this.getData();
        this.interval = setInterval(() => this.getData(), 15000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getData = async () => {
        try {
            const response = await this.apigClient.invokeApi({}, '/stocks/sample-tweet', 'GET', { headers: {}, queryParams: {} });
            this.setState({ tweetId: response.data.body["tweetId"], loading: true });
        } catch (e) {
            console.log("Derp... Sorry we failed to update results... ", e);
        }
    }

    render() {
        if (this.state.tweetId && this.state.tweetId !== 0) {
            return (
                // header with ticker?
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        key={this.state.tweetId}
                        addEndListener={(node, done) => {
                            node.addEventListener("transitionend", done, false);
                        }}
                        classNames="fade"
                    >
                        <div className="TwitterSample">
                            <Container className={this.state.loading ? "TweetContainer" : "TweetContainerV"}>
                                <TwitterTweetEmbed

                                    options={{ cards: 'hidden', maxWidth: 200 }}
                                    onLoad={() => this.setState({ loading: false })}
                                    tweetId={this.state.tweetId}
                                />
                            </Container>

                        </div>
                    </CSSTransition>

                </SwitchTransition>
            );
        }
        return null;
    }
}
export default TweetSampler;