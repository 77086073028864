import { Component } from 'react';
import { Fragment } from 'react';
import { withRouter } from 'react-router-dom'
import BannerLogo from '../BannerLogo/BannerLogo';
import SingleAssetDetail from '../SingleAssetDetail/SingleAssetDetail';
import SymbolSearchBarWithRouter from '../SymbolSearchBar/SymbolSearchBar';
import ReactGA from 'react-ga';

import './SingleAssetView.css'

class SingleAssetView extends Component {

    render() {
        document.title = "TickerPrime || Monitor Social Interest for " + this.props.match.params.symbol;
        ReactGA.set({ page: 'singleStock-'+this.props.match.params.symbol }); // Update the user's current page
        ReactGA.pageview('singleStock-'+this.props.match.params.symbol); // Record a pageview for the given page
        return (
            <Fragment>
                <div className="SingleAssetView">
                    <BannerLogo/>
                    <SymbolSearchBarWithRouter className="SymbolSearchBar" id="outlined-basic" variant="outlined" />
                    <SingleAssetDetail className="SingleAssetDetail" symbol={this.props.match.params.symbol} />
                </div>
            </Fragment>
        );
    }
}

export default withRouter(SingleAssetView);