import { Route, Switch, Redirect } from 'react-router-dom';
import TopStocksView from '../TopStocksView/TopStocksView';
import SingleAssetView from '../SingleAssetView/SingleAssetView';
import Footer from '../Footer/Footer';
import ReactGA from 'react-ga';


function TickerPrime() {
    const trackingId = "UA-204304712-1";
    ReactGA.initialize(trackingId);
    ReactGA.pageview('home');
    ReactGA.set({ page: 'stocks' });
    return (
        <div>
            <Switch>
                <Route path="/stocks/:symbol" exact>
                    <SingleAssetView />
                </Route>
                <Route path="/stocks" exact>
                    <TopStocksView />
                </Route>
                <Route path="/" exact>
                    <Redirect to="/stocks" />
                </Route>
                <Route>
                    <Redirect to="/" />
                </Route>
            </Switch>
            <Footer></Footer>
        </div>
    );
}

export default TickerPrime;