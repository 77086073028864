import React from 'react';
import ReactDOM from 'react-dom';

import "./index.css";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { BrowserRouter } from 'react-router-dom';
import TickerPrime from './components/TickerPrime/TickerPrime';


ReactDOM.render(
  (
    <BrowserRouter>
      <TickerPrime className="TickerPrime"/>
    </BrowserRouter>
  ),
  document.getElementById('root')
);